import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "project-select"
}

import type { Nullable } from '@/modules/shared/types/index.type';
import type { Project } from '@/modules/shared/types/project.type';
import {
  computed, ref, onBeforeMount, watch, toRefs,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MenuItem from '@/modules/shared/components/molecules/menuItem/MenuItem.vue';
import MenuList from '@/modules/shared/components/molecules/menuList/MenuList.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';
import { useStore } from '../../../store/hooks';

interface ProjectVideosPageNavMenuProps {
  isCollapse?: boolean;
  isResponsive?: boolean;
}

interface ProjectOption extends Partial<Project> {
  isAllProject?: true;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectVideosPageNavMenu',
  props: {
    isCollapse: { type: Boolean, default: false },
    isResponsive: { type: Boolean, default: false }
  },
  emits: ["update:currentPage"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { isCollapse, isResponsive } = toRefs(props);

const emit = __emit;

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useI18n();

const teamName = computed(() => route?.params?.teamName);
const projectKey = computed(() => route?.params?.projectKey);
const projectList = computed(() => store.state.projectList.projectList);

const allProjectItem: ProjectOption = {
  name: t('project.all.title'),
  isAllProject: true,
  key: '',
};

const projectOptions = computed<ProjectOption[]>(() => [
  allProjectItem,
  ...(projectList.value ?? []),
]);

const loadProjectState = computed(() => store.state.project.loadProjectState);

function getSelectedProject(): ProjectOption | undefined {
  if (projectKey.value) {
    return projectOptions.value.find((project) => project.key === projectKey.value);
  }

  return allProjectItem;
}

const selectedProject = ref<Nullable<ProjectOption>>(getSelectedProject());

watch(projectKey, () => {
  selectedProject.value = projectList.value && projectOptions.value.find((item) => item.key === projectKey.value);
});

const onSelectProject = (value: ProjectOption) => {
  selectedProject.value = value;

  if (selectedProject.value.isAllProject) {
    router.push(`/${teamName.value}/all-projects`);
  } else {
    router.push(`/${teamName.value}/projects/${selectedProject.value.key}`);
  }
};

const getMenuPath = (menu: string): string => {
  const basePath = projectKey.value ? `/${teamName.value}/projects/${projectKey.value}` : `/${teamName.value}/all-projects`;

  switch (menu) {
    case 'videos':
      return `${basePath}`;
    case 'issues':
      return `${basePath}/video-issues`;
    case 'recently-deleted':
      return `${basePath}/recently-deleted`;
    case 'membership':
      return `${basePath}/manage/members`;
    case 'manage-project':
      return `${basePath}/manage/general`;
    case 'import':
      return `${basePath}/imports`;
    default:
      return `${basePath}/${menu}`;
  }
};

const loadProjectList = async () => {
  if (!projectList.value) {
    await store.dispatch('loadProjectList');
  }
};

onBeforeMount(async () => {
  await loadProjectList();
});

watch(projectOptions, () => {
  selectedProject.value = getSelectedProject();
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isCollapse))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(SelectOption, {
            modelValue: selectedProject.value,
            "onUpdate:modelValue": onSelectProject,
            options: projectOptions.value,
            placeholder: "Select Project"
          }, null, 8, ["modelValue", "options"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(MenuList, {
      title: "Contents",
      isCollapse: _unref(isCollapse)
    }, {
      default: _withCtx(() => [
        _createVNode(MenuItem, {
          title: "Videos",
          iconClassName: "fas fa-play-circle",
          isCollapse: _unref(isCollapse),
          path: getMenuPath('videos')
        }, null, 8, ["isCollapse", "path"]),
        (projectKey.value)
          ? (_openBlock(), _createBlock(MenuItem, {
              key: 0,
              title: "Upload",
              iconClassName: "fas fa-cloud-arrow-up",
              isCollapse: _unref(isCollapse),
              path: getMenuPath('upload')
            }, null, 8, ["isCollapse", "path"]))
          : _createCommentVNode("", true),
        (projectKey.value)
          ? (_openBlock(), _createBlock(MenuItem, {
              key: 1,
              title: "Import",
              iconClassName: "fas fa-file-import",
              isCollapse: _unref(isCollapse),
              path: getMenuPath('import')
            }, null, 8, ["isCollapse", "path"]))
          : _createCommentVNode("", true),
        (projectKey.value)
          ? (_openBlock(), _createBlock(MenuItem, {
              key: 2,
              title: "Collections",
              iconClassName: "fas fa-rectangle-history",
              isCollapse: _unref(isCollapse),
              path: getMenuPath('collections')
            }, null, 8, ["isCollapse", "path"]))
          : _createCommentVNode("", true),
        _createVNode(MenuItem, {
          title: "Issues",
          iconClassName: "fas fa-bell",
          isCollapse: _unref(isCollapse),
          path: getMenuPath('issues')
        }, null, 8, ["isCollapse", "path"]),
        (projectKey.value)
          ? (_openBlock(), _createBlock(MenuItem, {
              key: 3,
              title: "Exported Files",
              iconClassName: "fa fa-file-export",
              isCollapse: _unref(isCollapse),
              path: getMenuPath('exported-files')
            }, {
              "menu-item-suffix": _withCtx(() => [
                _createVNode(Badge, {
                  title: "Beta",
                  badgeStyle: "highlight",
                  size: "small"
                })
              ]),
              _: 1
            }, 8, ["isCollapse", "path"]))
          : _createCommentVNode("", true),
        _createVNode(MenuItem, {
          title: "Recently Deleted",
          iconClassName: "fas fa-trash-can",
          isCollapse: _unref(isCollapse),
          path: getMenuPath('recently-deleted')
        }, null, 8, ["isCollapse", "path"])
      ]),
      _: 1
    }, 8, ["isCollapse"]),
    (projectKey.value && (!loadProjectState.value?.error || loadProjectState.value?.error?.statusCode !== 403))
      ? (_openBlock(), _createBlock(MenuList, {
          key: 1,
          title: "Project Management",
          isCollapse: _unref(isCollapse)
        }, {
          default: _withCtx(() => [
            _createVNode(MenuItem, {
              title: "Members",
              iconClassName: "fas fa-users",
              isCollapse: _unref(isCollapse),
              path: getMenuPath('membership')
            }, null, 8, ["isCollapse", "path"]),
            _createVNode(MenuItem, {
              title: "Manage Project",
              iconClassName: "fas fa-gear",
              isCollapse: _unref(isCollapse),
              path: !_unref(isResponsive) && getMenuPath('manage-project'),
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('update:currentPage','projectManage')), ["prevent"]))
            }, _createSlots({ _: 2 }, [
              (_unref(isResponsive))
                ? {
                    name: "menu-item-suffix",
                    fn: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1))
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["isCollapse", "path"])
          ]),
          _: 1
        }, 8, ["isCollapse"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})