import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "management-sidebar" }
const _hoisted_2 = { class: "back-button-container" }
const _hoisted_3 = { class: "sidebar-section-container" }
const _hoisted_4 = {
  key: 0,
  class: "sidebar-section"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "sub-items-container"
}
const _hoisted_7 = { class: "sub-item-title" }
const _hoisted_8 = { class: "description" }
const _hoisted_9 = { class: "sidebar-section project-manage-section" }
const _hoisted_10 = { style: { 'margin-left': 'auto'} }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';
import { canAccessFeature } from '@/middlewares/guard';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Badge from '@/modules/shared/components/atoms/badge/Badge.vue';

interface SidebarSubItem {
  title: string;
  description: string;
  path: string;
}

interface SidebarItem {
  sectionTitle: string;
  sectionIcon: string;
  key: string;
  subItems: SidebarSubItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TeamManagementSidebar',
  setup(__props) {

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const teamFeatures = computed(() => store.state.teamFeatures.teamFeatures);
const isManagerOrAdmin = computed(() => store.getters.isManagerOrAdmin);

const teamName = getQueryAsString(route.params, 'teamName');

const isCollapse = ref<Record<string, boolean>>({
  secure: false,
  videoPublishing: false,
  bumper: false,
  subtitle: false,
  videoOutput: false,
  videoInformation: false,
  importSettings: false,
  collection: false,
  plugIn: false,
});

const secureSubItems = computed<SidebarSubItem[]>(() => {
  const result = [
    {
      title: 'Geo-blocking Rules',
      description: t('teamSettings.sidebar.geoblock.description'),
      path: 'geo-blocking',
    },
    {
      title: 'Allowed/Blocked Websites',
      description: t('teamSettings.sidebar.accessControlPolicy.description'),
      path: 'allowed-blocked-website',
    },
    {
      title: 'Signed URL',
      description: t('teamSettings.sidebar.signedUrl.description'),
      path: 'signed-url',
    },
    {
      title: 'ClearKey Encryption',
      description: t('teamSettings.sidebar.clearKey.description'),
      path: 'clear-key',
    },
  ];

  if (canAccessFeature('multi-drm') && isManagerOrAdmin.value) {
    result.push({
      title: 'Multi-DRM',
      description: t('teamSettings.sidebar.multiDrm.description'),
      path: 'multi-drm',
    });
  }

  return result;
});

const sidebarItems = computed<SidebarItem[]>(() => [
  {
    sectionTitle: 'Secure & Protect',
    sectionIcon: 'fa-shield-alt',
    key: 'secure',
    subItems: secureSubItems.value,
  },
  {
    sectionTitle: 'Video Publishing',
    sectionIcon: 'fa-share-alt',
    key: 'videoPublishing',
    subItems: [
      {
        title: 'Video Embed Players',
        description: t('teamSettings.sidebar.player.description'),
        path: 'players',
      },
      {
        title: 'Playback Channels',
        description: t('teamSettings.sidebar.playbackChannel.description'),
        path: 'playback-channels',
      },
    ],
  },
  {
    sectionTitle: 'Bumper',
    sectionIcon: 'fa-play-circle',
    key: 'bumper',
    permissionRoles: ['user', 'manager'],
    subItems: [
      {
        title: 'Bumpers',
        description: t('teamSettings.sidebar.manageBumper.description'),
        path: 'bumpers',
      },
      {
        title: 'Bumper Insertion',
        description: t('teamSettings.sidebar.applyBumper.description'),
        path: 'bumper-insertion',
      },
    ],
  },
  {
    sectionTitle: 'Subtitle',
    sectionIcon: 'fa-message-captions',
    key: 'subtitle',
    subItems: [
      {
        title: 'Auto-generated Subtitle Profiles',
        description: t('teamSettings.sidebar.subtitleprofile.description'),
        path: 'subtitle-profiles',
      },
      {
        title: 'Bad Word List',
        description: t('teamSettings.sidebar.badwordlist.description'),
        path: 'badword-lists',
      },
    ],
  },
  {
    sectionTitle: 'Video Output',
    sectionIcon: 'fa-video',
    key: 'videoOutput',
    subItems: [
      {
        title: 'Presets',
        description: t('teamSettings.sidebar.preset.description'),
        path: 'presets',
      },
    ],
  },
  {
    sectionTitle: 'Video Information',
    sectionIcon: 'fa-rectangle-list',
    key: 'videoInformation',
    subItems: [
      {
        title: 'Custom Fields',
        description: t('project.manage.customFields.description'),
        path: 'custom-fields',
      },
      {
        title: 'Custom Image Fields',
        description: t('project.manage.customImageFields.description'),
        path: 'custom-image-fields',
      },
    ],
  },
  {
    sectionTitle: 'Import Settings',
    sectionIcon: 'fa-solid fa-file-import',
    key: 'importSettings',
    subItems: [
      {
        title: t('project.manage.videoSources.title'),
        description: t('project.manage.videoSources.description'),
        path: 'video-sources',
      },
    ],
  },
  {
    sectionTitle: 'Collection',
    sectionIcon: 'fa-rectangle-history',
    key: 'collection',
    subItems: [
      {
        title: 'Custom Collection Types',
        description: t('project.manage.collectionType.description'),
        path: 'collection-type',
      },
    ],
  },
  {
    sectionTitle: 'Plug-in',
    sectionIcon: 'fa-plug rotate',
    key: 'plugIn',
    subItems: [
      {
        title: 'Uploader Forms',
        description: t('teamSettings.sidebar.formUpload.description'),
        path: 'forms',
      },
    ],
  },
]);

function onToggleCollapse(key: string) {
  isCollapse.value[key] = !isCollapse.value[key];
}

function isTeamFeature(key: string) {
  return teamFeatures.value && teamFeatures.value[key];
}

function canShowSubSection(subSection: SidebarSubItem) {
  if (subSection.path === 'custom-image-fields') {
    return isTeamFeature('custom-image');
  }

  if (subSection.path === 'subtitle-profiles') {
    return isTeamFeature('auto-generate-caption');
  }
  return true;
}

function canShowSection(section: SidebarItem) {
  if (section.key === 'bumper') {
    return isTeamFeature('bumper');
  }
  return true;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: `/${_unref(teamName)}/projects`
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa fa-chevron-left" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_unref(t)('common:button.back')), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "sidebar-title" }, "Management", -1)),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebarItems.value, (sidebarItem) => {
        return (_openBlock(), _createElementBlock("div", {
          key: sidebarItem.key
        }, [
          (canShowSection(sidebarItem))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  class: "section-title",
                  onClick: ($event: any) => (onToggleCollapse(sidebarItem.key))
                }, [
                  _createElementVNode("i", {
                    class: _normalizeClass(`section-icon fas ${sidebarItem.sectionIcon}`)
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(sidebarItem.sectionTitle) + " ", 1),
                  _createElementVNode("i", {
                    class: _normalizeClass(["fas fa-chevron-down chevron-icon", { 'flip': !isCollapse.value[sidebarItem.key] }])
                  }, null, 2)
                ], 8, _hoisted_5),
                (!isCollapse.value[sidebarItem.key])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sidebarItem.subItems, (subItem) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: subItem.path
                        }, [
                          (canShowSubSection(subItem))
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                class: "link",
                                to: `/${_unref(teamName)}/manage/${subItem.path}`
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_7, _toDisplayString(subItem.title), 1),
                                  _createElementVNode("div", _hoisted_8, _toDisplayString(subItem.description), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, {
          class: "section-title",
          to: `/${_unref(teamName)}/manage/team-settings`
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-gear icon-cog" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_unref(t)('teamSettings.header.title')) + " ", 1),
            _createElementVNode("span", _hoisted_10, [
              _createVNode(Badge, {
                title: _unref(t)('common:default'),
                badgeStyle: "info",
                theme: "dark",
                size: "small"
              }, null, 8, ["title"])
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}
}

})